"use strict";

import Vue from "vue";
import axios from "axios";
import { Toast } from 'vant';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    baseURL: process.env.VUE_APP_URL,
    timeout: 5000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
        // Do something before request is sent
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        if (response.data.code === 1) {
            return response.data
        } else {
            Toast.fail(response.data.msg);
        }
        return Promise.reject(response.data.msg);
    },
    function(error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

Vue.prototype.axios = _axios;