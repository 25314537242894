<template>
  <div class="app" :style="{height: height + 'px'}">
    <img src="./assets/banner.jpg" height="240" width="100%"/>
    <div class="content">
      <div class="card">
        <van-cell-group>
          <div class="title">
            <span class="line"></span><span>基本信息</span>
          </div>
          <van-field v-model="form.phone" type="tel" placeholder="请输入联系方式" @input="phoneHandler" :disabled="!canSubmit">
            <span slot="label" class="input_label">联系方式</span>
          </van-field>
          <van-field v-model="form.code" type="digit" placeholder="请输入验证码" @input="codeHandler" :disabled="!canSubmit">
            <span slot="label" class="input_label">验证码</span>
            <van-button
              slot="button"
              size="mini"
              type="info"
              class="code_btn"
              :disabled="!canSendCode || !canSubmit"
              @click="getSmsCode"
            >
              <span v-if="timeCount == 0">{{ sendCodeStr }}</span
              ><span v-else>{{ timeCount }}s</span>
            </van-button>
          </van-field>
          <van-field v-model="form.name" type="text" placeholder="请输入姓名" :disabled="!canSubmit">
            <span slot="label" class="input_label">家长姓名</span>
            <van-radio-group slot="extra" v-model="form.gender" direction="horizontal" icon-size="16px" :disabled="!canSubmit">
              <van-radio :name="0">先生</van-radio>
              <van-radio :name="1">女士</van-radio>
            </van-radio-group>
          </van-field>
          <van-field>
            <span slot="label" class="input_label">孩子性别</span>
            <van-radio-group slot = "input" v-model="form.studentGender" direction="horizontal" icon-size="16px" :disabled="!canSubmit">
              <van-radio :name="0">男生</van-radio>
              <van-radio :name="1">女生</van-radio>
            </van-radio-group>
          </van-field>
          <van-field>
            <span slot="label" class="input_label">考试年份</span>
            <van-radio-group slot = "input"
                v-model="form.year"
                direction="horizontal"
                icon-size="16px"
                @change="yearChange"
                :disabled="!canSubmit">
                <van-radio
                  v-for="(item, i) of testYears"
                  :key="i"
                  :name="item"
                  >{{ item }}</van-radio
                >
              </van-radio-group>
          </van-field>
        </van-cell-group>
      </div>

      <div class="card" style="margin-top: 10px">
        <van-cell-group>
          <div class="title" style="margin-bottom: 15px">
            <span class="line"></span><span>考试项目</span>
          </div>

          <van-row style="padding-left: 18px; margin: 10px 0" v-for="(item, i) of form.items" :key="i">
            <van-col span="10" @click="pickerHandler(item.classify)">
              <van-row class="choose-item">
                <van-col span="20" class="text" style="padding-left: 10px;">{{ item.courseName }}</van-col>
                <van-col span="4"  class="text"><van-icon name="arrow-down"/></van-col>
              </van-row>
            </van-col>
            <van-col span="14">
              <van-row v-if="item.unit === 1">
                <van-col span="8" style="padding-top: 5px;">
                  <input type="number" class="num_input" style="width: 60%" v-model="item.minute" :disabled="!canSubmit"/><span class="num_label">分</span>
                </van-col>
                <van-col span="8"  style="padding-top: 5px;">
                  <input type="number" class="num_input" style="width: 60%" v-model="item.second" :disabled="!canSubmit"/><span class="num_label">秒</span>
                </van-col>
                <van-col span="8">
                  <span class="score" v-show="item.score">{{ item.score }}</span>
                </van-col>
              </van-row>
              <van-row v-else>
                <van-col span="16" style="padding-top: 5px;">
                  <input type="number" class="num_input" style="width: 80%" v-model="item.result" :disabled="!canSubmit"/>
                  <span class="num_label" v-if="item.unit === 0">秒</span>
                  <span class="num_label" v-else-if="item.unit === 3">次</span>
                  <span class="num_label" v-else-if="item.unit === 2">米</span>
                </van-col>
                <van-col span="8">
                  <span class="score" v-show="item.score">{{ item.score }}</span>
                </van-col>
              </van-row>
            </van-col>
          </van-row>

          <van-row style="margin: 20px 0 10px 0; padding-left: 20px">
            <van-col span="11" class="score_label">
              <span style="font-size: 20px; font-weight: bold">50分</span>
              <span>以上项目满分</span>
            </van-col>
            <van-col span="2">&nbsp;</van-col>
            <van-col span="11" class="score_label label_red">
              <span style="font-size: 20px; font-weight: bold"
                >{{ totalScore }}分</span
              >
              <span>目前得分</span>
            </van-col>
          </van-row>

          <div class="tips">
            *体育考试总分为70分，页面仅提供统一考试成绩转换（满分50分）,
            不包括体育素质综合评价分数（满分20分）。
          </div>
          <van-popup v-model="showCourse" position="bottom">
            <van-picker
              ref="picker"
              title="考试项目"
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="onCancel"
            ></van-picker>
          </van-popup>
        </van-cell-group>
      </div>

      <div class="remark">
        <span
          >*转换数据参考<a
            href="http://www.gz.gov.cn/gfxwj/sbmgfxwj/gzsjyj/content/post_7376379.html"
            target="_blank"
            >《广州市初中学业水平考试体育与健康考试实施意见的通知》</a
          >。</span
        >
        <span>*转换成绩仅供参考，本页面不对转换结果承担任何法律责任。</span>

        <div style="margin: 15px 0 30px 0">
          <van-button type="default" size="large" class="reset" @click="reset">重设</van-button>
          <van-button type="info" size="large" class="query" @click="submit" :disabled="!canSubmit">立即查询</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return {
      height: 0,
      form:{
        phone: '',
        code: '',
        name: '',
        gender: 0,
        studentGender: 0,
        year: '',
        items: []
      },
      totalScore: 0,
      showCourse: false,
      testYears:[],
      courses: [],
      classifys: [],
      current: 0,
      columns: [],
      canSendCode: false,
      sendCodeStr: '获取',
      timeCount: 0,
      timer: '',
      validSmsCode: false,
      canSubmit: true
    }
  },
  created() {
     this.getTestYear()
     this.$nextTick(()=>{
      this.height = document.getElementsByClassName('content')[0].clientHeight + 240;
     })
  },
  methods: {
    getSmsCode(){
      this.canSendCode = false
      this.axios.post('/user/send_code/' + this.form.phone).then(rep=>{
        if(rep.code === 1){
          this.$toast.success('发送成功！');
          this.timeCount = 60
          this.sendCodeStr = "重发"
          let time = setInterval(() => {
            if(this.timeCount == 0){
              clearInterval(time)
              this.canSendCode = true
              return
            }
            this.timeCount -=1
          }, 1000)
        } else {
          this.canSendCode = true
          this.sendCodeStr = "重发"
        }
      })
    },
    getTestYear(){
      this.axios.get('/user/test/year').then(rep=>{
        this.testYears = rep.data
        this.form.year = this.testYears[0]
      })
    },
    getCourse(){
      this.axios.get("/course/" + this.form.year).then(rep=>{
        this.courses = rep.data
        let classifys = Array.from(new Set(this.courses.map(o=> o.classify))).sort();
        this.form.items = []
        for(let classify of classifys){
          let item = this.courses.filter(o=> o.classify == classify)[0]
          let temp = {
            courseId: '',
            courseName: "请选择",
            classify: item.classify,
            result: '',
            score: '',
            unit: ''
          }
          if(item.unit == 1){
            temp['minute'] = ''
            temp['second'] = ''
          }
          this.form.items.push(temp)
        }
        this.$forceUpdate()
        if(this.$refs.picker){
          this.$refs.picker.setColumnIndex(0)
        }
      })
    },
    vailCode(){
      this.axios.get('/user/info/'+this.form.code).then(rep=>{
        if(rep.code === 1){
          this.validSmsCode = true
          if(rep.data){
            let user = rep.data
            this.form.name = user.name
            this.form.gender = user.gender
            this.form.studentGender = user.studentGender
            this.form.year = user.year
          }
        }
      })
    },
    calScore(){
      this.axios.post('/user/submit', this.form).then(rep=>{
        if(rep.code === 1) {
          let data = rep.data
          data.forEach(it=> {
            let item = this.form.items.find(o=> o.courseId === it.courseId)
            if(item){
              this.totalScore += it.score
              item.score = it.score + '分'
            }
          })
        }
      })
    },
    codeHandler(){
      clearTimeout(this.timer)
      this.timer = setTimeout(() =>{
        this.vailCode()
      }, 1500)
    },
    phoneHandler(){
      if(this.form.phone){
        this.canSendCode = true
      } else {
        this.canSendCode = false
      }
    },
    pickerHandler(classify){
      if(!this.canSubmit){
        return
      }
      this.current = this.form.items.findIndex(o=> o.classify === classify)
      this.columns = ['请选择']
      this.columns = this.columns.concat(this.courses.filter(it=> it.classify === classify && (it.gender === this.form.studentGender || it.gender == -1)).map(o=> o.courseName))
      this.showCourse = true
      this.$nextTick(() => {
        this.$refs.picker.setColumnIndex(this.columns.findIndex(o=> o.courseId === this.form.items[this.current].courseId))
      })
    },
    yearChange(){
      this.getCourse()
    },
    onConfirm(val){
        if(val == '请选择'){
          this.form.items[this.current].courseId = ''
          this.form.items[this.current].courseName = '请选择'
          this.form.items[this.current].unit = ''
        } else {
          let item = this.courses.filter(o=> o.courseName === val)[0]
          this.form.items[this.current].courseId = item.courseId
          this.form.items[this.current].courseName = item.courseName
          this.form.items[this.current].unit = item.unit
        }
        this.showCourse = false
    },
    onCancel(){
      this.showCourse = false
    },
    reset(){
      this.totalScore = 0
      this.getCourse()
      this.canSubmit = true
    },
    submit(){
      if(!this.form.phone){
        this.$notify({ type: 'danger', message: '请填写联系方式！' });
        return
      }
      if(!this.validSmsCode){
        this.$notify({ type: 'danger', message: '手机未验证或验证失败！' });
        return
      }
      if(!this.form.name){
        this.$notify({ type: 'danger', message: '请填写家长姓名！' });
        return
      }
      if (this.form.items.filter(o=> o.courseId || (o.unit===1 && o.minute && o.second) || (o.unit != 1 && o.result)).length == 0){
          this.$notify({ type: 'danger', message: '请填写科目成绩！' });
          return
      }
      for(let item of this.form.items){
        if(item.unit === 1){
          item.minute = item.minute ? parseInt(item.minute) : 0
          item.second = item.second ? parseInt(item.second) : 0
          item.result = (parseInt(item.minute) * 60 + parseInt(item.second))
        } else {
          item.result = item.result ? parseFloat(item.result) : 0
          // item.result = parseFloat(item.result)
        }
      }
      this.canSubmit = false
      this.calScore()
    }
  }
};
</script>

<style>
.app{
    background-color: #e4e4e6;
    font-size: 12px;
    height: 100%;
    position: absolute;
    max-width: 500px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    /* bottom: 0; */
}
.content {
  width: 100%;
  position: absolute;
  top: 205px;
}
.label_red {
  color: #d40000;
}
.reset {
  font-size: 16px;
  width: 30%;
  background-color: #b2b2b5;
  border-radius: 15px;
  margin-right: 10px;
}
.query {
  font-size: 16px;
  width: 65%;
  border-radius: 15px;
  background-color: #3377FF;
}
.remark {
  margin-top:10px;
  background: #FFFFFF;
  padding: 10px 20px;
}
.reset .van-button__text{
  color: #FFFFFF;
}
.query .van-button__text{
  color: #FFFFFF;
}
.remark span{
  display: block;
  color: #898990;
}
.remark a {
  color: #3B7CFF;
}
.tips{
  color: #898990;
  padding-left: 20px;
}
.score_label {
  border: 1px solid #E7E7E9;
  border-radius: 5px;
  text-align:center;
  padding: 10px 0;
}
.score_label span{
  display: block;
}
.num_input{
  display: inline-block;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #3377FF;
  text-align: center;
  color: #323233;
  border-radius: 0px;
}
.num_label{
  color: #3377FF;
}
.score {
  display: inline-block;
  background-color: #CAF5FF;
  /* margin-left: 10px; */
  padding: 2px 10px;
  font-weight: bold;
  color: #3377FF;
  border-radius: 5px;
  border: 1px solid #3377FF;
}
.card {
    margin: 0 15px;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 10px 25px;
}
.title .line{
  display: inline-block;
  width: 3px;
  height: 16px;
  background-color: #3377FF;
  border-radius: 20px;
  margin-right: 13px;
}
.title span {
  color: #303030;
  font-size: 16px;
  vertical-align: middle;
}
.input_label {
  color: #898990;
  font-size: 14px;
}
.code_btn {
  width: 50px;
  border-radius: 5px;
  vertical-align: middle;
  font-size: 14px;
}
.van-radio--horizontal {
  margin-right: 6px;
}
.van-field__label{
  margin-right: 0;
  width: 70px;
}
.van-radio__label{
  margin-left: 4px;
}
.choose-item {
  height: 24px;
  width: 90%;
  border-radius: 4px;
  border: 1px solid #898990;
  /* padding: 2px 8px 5px 8px; */
  color: #9c9c9e;
}
.choose-item .text{
  line-height: 24px;
  vertical-align: middle;
  word-break: keep-all;
}
[class*=van-hairline]:after{
  border: none;
}
</style>
